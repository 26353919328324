import * as React from 'react'
import * as Styles from './index.module.sass'
import { Link } from 'gatsby'
import ReactVideo from '../components/reactvideo'
import useWindowSize from '../utils/useWindowSize'
import Seo from '../components/seo'
const IndexPage = () => {
  const { ar } = useWindowSize();
  return (
    <>
      <Seo
        title={`Главная`}
        description={`Видеоролики, графика и 3D визуализации для любых задач бизнеса и не только.`}
      />
      <div>
        <div className={`react-player-wrapper ${Styles.videowrap}`}>
          <div className={`is-overlay ${Styles.videowrap}`}></div>
          <ReactVideo
            width="100%"
            height="100%"
            playing={true}
            playsinline
            loop={true}
            muted={true}
            video={(ar > 1) ? `https://cdn.mustbefamily.com/main.mp4` : `https://cdn.mustbefamily.com/main_m.mp4`}
            poster={(ar > 1) ? `https://cdn.mustbefamily.com/main.jpg` : `https://cdn.mustbefamily.com/main_m.jpg`}
            className={`${Styles.video}`}
            outerclassName={`${Styles.videowrap}`}
            alt={`Must Be Family шоурил`} />
        </div>
      </div>
      <div className={`${Styles.center}`}>
        <picture>
          <source media={`(max-width: 1023px)`} srcSet={`/images/title-m_1x.png 1x, /images/title-m_2x.png 2x`} type={`image/png`} />
          <source media={`(min-width: 1024px)`} srcSet={`/images/title_1x.png 1x, /images/title_2x.png 2x`} type={`image/png`} />
          <img alt={`Mustbefamily`} src={`/images/title_1x.png`} type={`image/png`} style={{ minWidth: `300px` }} />
        </picture>
        <h2>Мы создаём визуальный контент</h2>
        <Link to={`/video`}>Смотреть</Link>
      </div>
    </>
  )
}

export default IndexPage
